export const button = {
  goBack: 'Go back',
  continue: 'Continue',
  next: 'Next',
  logout: 'Log out',
  cancel: 'Cancel',
  dismiss: 'Dismiss',
  edit: 'Edit',
  tryAgain: 'Try again',
  reload: 'Reload page',
  yes: 'Yes',
  no: 'No',
  contactCustomerCare: 'Contact Customer Care',
  done: 'Done',
  delete: 'Delete',
}

export const notes = {
  progressSaved: 'Pause and resume at any step, your progress will save as you go',
  progressSavedShort: 'Your progress is auto-saved',
}

export const links = {
  depositAccountAgreement: 'Deposit Account Agreement',
}

export const tooltip = {
  whyDoWeCollectThisInformation: 'Why do we collect this information?',
}

export const inputs = {
  addressAutocompleteEnterAddressManually: 'Enter address manually',
  addressAutocompleteHelperText: 'PO boxes are not accepted',
  addressAutocompleteRequiredText: 'Address required',
  firstNameLabel: 'First name',
  lastNameLabel: 'Last name',
}

export const error = {
  required: 'Required',
  description: 'Enter at least 16 characters',
  invalidPhone: 'Enter a valid phone number',
  invalidExceedsMaxPercentage: "Your business' ownership structure exceeds 100%",
  invalidZeroPercentage:
    'Enter a percentage greater than 0%, you only need to add legal co-owners with 25% or more ownership of the business',
  onlyAlphabeticalLetters: 'Enter only alphabetical letters',
  invalidEin: 'Enter a 9-digit EIN',
  invalidSSN: 'Enter your 9-digit SSN',
  maxLength: 'Maximum character limit of {{limit}}',
  minLength: 'Enter at least {{minLength}} characters',
  maxDigits: 'Maximum {{limit}} digits',
  invalidStreetAddress: 'Maximum character limit of 30 reached',
  invalidCity: 'Maximum character limit of 30 reached',
  invalidZipCode: 'Enter a 5-digit ZIP code',
  invalidState: 'Select a state',
  generic: 'Something went wrong. Please try again.',
  characterRange: 'Enter between {{minLength}} and {{maxLength}} characters',
  invalidBusinessName: 'Maximum character limit of 50',
  missingCoOwners: 'Add all legal co-owners to continue. If you do not have co-owners, edit your profile.',
  zeroFullTimeEmployee: 'If it’s just you, enter 1',
  zeroYearsAtLocation: 'If less than 12 months, round up to 1 year',
  noPOBox: 'Address cannot be a PO Box',
  date: {
    invalid: 'Enter a valid date MM/DD/YYYY',
    future: 'Enter a date in the past',
    under18: '{{name}} must be 18 years or older to apply',
    before1900: 'Enter a year after 1900',
    you: 'You',
    controlPerson: 'The control person',
    ubo: 'This owner',
  },
  cardDetails: {
    cardholderName: 'Enter the name on the card',
    cardNumber: 'Enter a valid card number',
    expirationDate: {
      invalid: 'Enter a valid expiry date',
      expired: 'Invalid expiry date',
    },
    cvv: 'Enter a 3-digit code',
    zipCode: 'Enter a 5-digit ZIP code',
    cardFundingError: {
      default: 'Something went wrong. Check your deposit details and try again.',
      prepaidCard: 'Prepaid cards cannot be used with NorthOne. Please try again with a debit card.',
      creditCard: 'Credit cards cannot be used with NorthOne. Please try again with a debit card.',
      requestBlocked: 'Your bank has blocked transactions on this card. Please try again with another debit card.',
      incorrectAddress: 'The address information you’ve entered doesn’t match your bank records. Please try again.',
      internationalCard: 'International cards cannot be used with NorthOne. Please try again with a US debit card.',
      insufficientFunds:
        'Looks like there aren’t enough funds on this card. Add funds to the card and retry, or use another debit card.',
    },
    billingAddress: {
      suite: 'Max 30 characters',
    },
  },
  invalidEmail: 'Enter a valid email',
  duplicateEmail: 'Enter a unique email for each owner',
  errorModal: {
    title: `There's a problem loading this page`,
    description: 'We were able to log you in but something went wrong while loading this page. Please try again.',
  },
  genericScreen: {
    title: 'Something went wrong',
    subtitle: 'Please try again or ',
    contactSupport: 'contact support',
    button: 'Try again',
  },
  genericBanner: {
    text: 'Something went wrong! Please try again. If you keep having issues, contact',
    customerSupport: 'customer support',
  },
}

export const userActionDropdown = {
  menu: {
    support: 'Support',
    faq: 'FAQs',
  },
  logoutModal: {
    title: 'Log out?',
    description: 'Your application progress will be saved. You can come back and continue at any time.',
  },
}
