import {
  Body,
  BodySizeEnum,
  Box,
  ButtonGroup,
  DiagonalMutedGradient2,
  FlatButton,
  ICONS,
  ProgressNote,
} from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import { ReactNode, useEffect, useRef, useState } from 'react'

import ResponsiveOnboardingHeader from '@/components/ResponsiveOnboardingHeader'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'

import { MAX_CONTENT_WIDTH_LG, MAX_CONTENT_WIDTH_MD } from './constants'

const greenGlassGradientBackground =
  'linear-gradient(180deg, rgba(241, 254, 241, 0.00) 0%, rgba(241, 254, 241, 0.90) 36.5%, #F1FEF1 100%)'

const buttonBackgroundGradient =
  'linear-gradient(180deg, rgba(255, 254, 251, 0.00) 0%, rgba(255, 254, 251, 0.90) 36.5%, #FFFEFB 100%)'

const getMobileButtonGroupStyles = (hasGradientBackground: boolean): React.CSSProperties => {
  const background = hasGradientBackground ? greenGlassGradientBackground : buttonBackgroundGradient

  return {
    position: 'fixed',
    boxSizing: 'border-box',
    zIndex: 2,
    bottom: 0,
    background,
    padding: 20,
  }
}

export interface BaseContentLayoutProps {
  headingText?: string
  tooltipSubheading?: ReactNode
  titleZIndex?: number
  subHeadingText?: string | ReactNode
  children?: ReactNode
  primaryButton?: ReactNode
  secondaryButton?: ReactNode
  tertiaryButtonProps?: {
    text: string
    onClick: () => void
  }
  alertComponent?: ReactNode
  disclaimerComponent?: ReactNode
  showProgressAutoSavedNote?: boolean
  hasGradientBackground?: boolean
  maxContentSize?: 'md' | 'lg'
}

export const BaseContentLayout = ({
  headingText,
  subHeadingText,
  tooltipSubheading,
  children,
  primaryButton,
  secondaryButton,
  tertiaryButtonProps,
  alertComponent,
  disclaimerComponent,
  showProgressAutoSavedNote,
  maxContentSize = 'md',
  hasGradientBackground = false,
  titleZIndex = 1,
}: BaseContentLayoutProps) => {
  const { isMobileSize } = useMobileScreenSize()
  const t = useOnboardingTranslations()
  const buttonContainerRef = useRef<HTMLDivElement>(null)
  const [buttonContainerHeight, setButtonContainerHeight] = useState(0)

  const backgroundColor = hasGradientBackground ? 'transparent' : '$background'

  useEffect(() => {
    if (!buttonContainerRef.current) return
    if (!isMobileSize) {
      setButtonContainerHeight(0)
      return
    }
    setButtonContainerHeight(buttonContainerRef.current.offsetHeight + 20)
  }, [buttonContainerRef, isMobileSize])

  const paddingHorizontal = isMobileSize ? '$4' : '$16'
  const paddingTop = isMobileSize ? '$6' : '$16'
  const paddingBottom = isMobileSize ? 0 : disclaimerComponent ? '$10' : '$17'
  const maxContentWidth = maxContentSize === 'md' ? MAX_CONTENT_WIDTH_MD : MAX_CONTENT_WIDTH_LG

  const topLevelContainerStyles = {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    overflowY: 'auto',
    width: '$full',
    gap: '$6',
    backgroundColor,
    paddingHorizontal,
    paddingTop,
    paddingBottom,
  }

  const pageContentStyles = {
    gap: '$8',
    width: maxContentWidth,
    maxWidth: '100%',
    zIndex: 2,
    ...(isMobileSize && { gap: '$4', flex: 1 }),
  }
  const headerStyles = {
    width: '$full',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: isMobileSize ? '$4' : '$8',
    zIndex: titleZIndex,
  }
  const childrenStyles = {
    position: 'relative',
    width: '$full',
    zIndex: 2,
    gap: '$6',
    ...(isMobileSize && { paddingBottom: buttonContainerHeight }),
  }
  const buttonGroupStyles: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'column',
    zIndex: 1,
    maxWidth: MAX_CONTENT_WIDTH_MD,
    width: '100%',
    gap: 24,
    ...(isMobileSize && getMobileButtonGroupStyles(hasGradientBackground)),
  }

  const progressSaved = isMobileSize ? t('notes.progressSavedShort') : t('notes.progressSaved')

  return (
    <DiagonalMutedGradient2>
      <Box testID="top-level-container" sx={topLevelContainerStyles}>
        <Box testID="content-container" sx={pageContentStyles}>
          {alertComponent}
          <Box testID="header-container" sx={headerStyles}>
            <Box sx={{ gap: '$4' }}>
              <ResponsiveOnboardingHeader largerSize="md">{headingText}</ResponsiveOnboardingHeader>
              {tooltipSubheading}
            </Box>
            {subHeadingText && <Body size={isMobileSize ? BodySizeEnum.SM : BodySizeEnum.MD}>{subHeadingText}</Body>}
          </Box>
          <Box testID="children-container" sx={childrenStyles}>
            {children}
            {disclaimerComponent && isMobileSize && (
              <Box
                sx={{
                  marginTop: '$6',
                  borderTopWidth: 1,
                  borderColor: '$charcoal3',
                  width: '$full',
                  gap: '$4',
                  pt: '$5',
                }}
              >
                {disclaimerComponent}
              </Box>
            )}
          </Box>
          <div data-test-id="button-group-container" style={buttonGroupStyles} ref={buttonContainerRef}>
            <ButtonGroup>
              {primaryButton}
              {isMobileSize ? null : secondaryButton}
            </ButtonGroup>
            {tertiaryButtonProps && (
              <FlatButton
                onPress={tertiaryButtonProps.onClick}
                testID="base-form-layout-flat-button"
                icon={ICONS.ArrowIcon}
                fullWidth
              >
                {tertiaryButtonProps.text}
              </FlatButton>
            )}
            {showProgressAutoSavedNote && <ProgressNote note={progressSaved} icon={ICONS.BookmarkIcon} />}
          </div>
        </Box>
        {disclaimerComponent && !isMobileSize && (
          <BaseContentFooterSection>{disclaimerComponent}</BaseContentFooterSection>
        )}
      </Box>
    </DiagonalMutedGradient2>
  )
}

/**
 * Wrapper component for page footers - required for disclaimers
 */
export const BaseContentFooterSection = ({ children }: { children: React.ReactNode; fullWidth?: boolean }) => {
  return (
    <Box
      testID="footer-container"
      sx={{
        borderTopWidth: 1,
        borderColor: '$charcoal3',
        width: '$full',
        alignSelf: 'flex-end',
        paddingTop: '$5',
        alignItems: 'center',
        marginTop: '$10',
      }}
    >
      <Box sx={{ width: '$full', gap: '$4' }}>{children}</Box>
    </Box>
  )
}
