import { useTranslation } from 'react-i18next'

import { PathsToProps } from '@/i18n/types'
import { accountTopUps } from '@/routes/account-top-ups/locales/en'
import { accountUsage } from '@/routes/account-usage/locales/en'
import { businessDetails } from '@/routes/business-details/locales/en'
import { finishUp } from '@/routes/finish-up/locales/en'
import { ownership } from '@/routes/ownership/locale/en'

import { gettingStarted } from '../../../routes/welcome/getting-started/getting-started'
import { applicationForm } from './application-form'
import { applicationStatus } from './application-status'
import { disclaimers } from './disclaimers'
import { emailVerify } from './email-verify'
import { funding } from './funding'
import { funnel } from './funnel'
import { inAppDocumentCapture } from './in-app-document-capture'
import { plaidFunding } from './plaid-funding'
import { button, error, inputs, links, notes, tooltip, userActionDropdown } from './shared'
import { topUpOptIn } from './top-up-opt-in'

/**
 * Translations for English
 */
export const en = {
  businessDetails,
  ownership,
  funnel,
  applicationForm,
  button,
  inputs,
  disclaimers,
  error,
  userActionDropdown,
  funding,
  applicationStatus,
  inAppDocumentCapture,
  accountUsage,
  emailVerify,
  gettingStarted,
  plaidFunding,
  topUpOptIn,
  accountTopUps,
  finishUp,
  links,
  notes,
  tooltip,
}

type EnKeys = keyof typeof en
type EnValues = (typeof en)[EnKeys]

export type I18nPath = PathsToProps<Record<EnKeys, EnValues>, string>

export const useOnboardingTranslations = () => {
  const { t } = useTranslation()
  return t as (dottedString: I18nPath, args?: Record<string, string | number>) => string
}
